/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta, SideBySide} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/bernafon/'], ['en', 'https://www.hear.com/hearing-aids/brands/bernafon/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/bernafon/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/bernafon/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "bernafon-swiss-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bernafon-swiss-technology",
    "aria-label": "bernafon swiss technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bernafon: Swiss technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing and understanding are basic needs of every individual. With Swiss innovation and high-quality products, Bernafon is uniquely situated to help people with hearing loss regain their quality of life and improve communication with family and friends. Bernafon ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " use state-of-the-art hearing technology to compensate for hearing loss while providing additional personal benefits like wireless connectivity for hands-free use of your mobile phone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The section below contains general information about Bernafon and the hearing aids they offer. At hear.com, we offer hearing aids from all the leading manufacturers. However, this page is primarily for informational use and does not necessarily mean that we carry hearing from this particular ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "brand"), ". Nonetheless, we hope this brief overview of Bernafon hearing aids will give you a glimpse of the types of hearing aids that are currently available on the market."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "advanced-technology-from-bernafon",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advanced-technology-from-bernafon",
    "aria-label": "advanced technology from bernafon permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advanced technology from Bernafon"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Music and certain elements of speech are often only perceptible in really high frequency ranges. With hearing loss, the natural ability to recognize those frequencies naturally can get lost. Modern, digital hearing aids help restore hearing in the high-frequency range. This feature is primarily helpful for speech recognition, but it can also improve the way you enjoy music."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But how exactly do hearing aids affect perception and the sound of music? With its unique technology ChannelFree™, the Swiss manufacturer Bernafon has caused a stir in this domain of hearing acoustics. In fact, the innovative technology for signal processing is especially suited for music. This allows the incoming audio signal to be used as a whole, without splitting it into multiple channels. High-pitched tones, which are essential for the harmonious perception of music, can be rendered naturally and without any loss of audio quality. The result is a significantly natural and rich sound."), "\n", React.createElement(LandingPageCta, {
    copy: "Try hearing aids near you",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bernafon-hearing-aid-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bernafon-hearing-aid-features",
    "aria-label": "bernafon hearing aid features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bernafon hearing aid features"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bernafon-hearing-aid.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Depending on the performance level, Bernafon hearing aids are equipped with Audio Efficiency™ technology and feature numerous functions that work in unison, enabling the wearer to experience sound clearly in any environment. This includes functions that improve speech comprehension, ensure maximum hearing comfort in loud environments, and eliminate audio feedback before it becomes problematic."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The “BeFlex” technology is a new feature designed to facilitate the customer fitting process. The advantage: the audiologist or hearing aid specialist can program the tester device to work with basic, mid-range, or premium features. This allows the client to switch between the different classes, giving you a better feel of which setting works best for you. This not only saves time and effort, but also brings you closer to a life without hearing loss.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "phone-and-tv-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phone-and-tv-connectivity",
    "aria-label": "phone and tv connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phone and TV connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bernafon hearing systems from the Chronos and Acriva series can connect wirelessly to other devices via the ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth"), " interface called SoundGate. With an additional TV-adapter, the television sound can also be received wirelessly via the hearing aids, without any annoying background noise but with superior sound quality. You can also connect a landline phone to the hearing aids with a special phone adaptor."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to SoundGate, Bernafon offers many of the same wireless benefits as some of the other hearing aid manufacturers. These great features can also be found on some of our other hearing aids. To learn more about finding the best hearing loss solution for you, simply give us a call – we are more than happy to help you with all your hearing needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-through-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-through-hearcom",
    "aria-label": "hearing aids through hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids through hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bernafon is just one example of a hearing aid manufacturer that is taking advantage of modern technology and passing the benefits along to people just like you. With hear.com, you have many options to choose from! We carry the best modern hearing aids on the market at competitive prices. What’s great about our services is that we help you understand how hearing aids can dramatically change the way you hear the world."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our consultants are personal guides, joining every customer on their journey toward better hearing. We equip you with all the necessary information about hearing loss and hearing aids, so that you are completely satisfied with your new devices once you purchase them. And best of all, our consultations are absolutely free!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try the latest hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
